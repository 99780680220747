import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "antd";
import logo from "../../assets/img/logo.png";
import play from "../../assets/img/check-ch.png";
import whatsappimage from "../../assets/img/whatsappicon.png";
import wallet from "../../assets/img/wallet.png";
import callicon from "../../assets/img/callicon.png";
import staricon from "../../assets/img/staricon.png";
import playoff from "../../assets/img/icons8-cross-48.png";
import chart from "../../assets/img/icons8-signal-96.png";
// import call_bg from "../../assets/img/call_bg.png";
import { FaPlay } from "react-icons/fa";
import { VscDebugStart } from "react-icons/vsc";
import { FcStart } from "react-icons/fc";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { FaArrowRight, FaTelegram } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdPlay } from "react-icons/io";

import { BsGraphUpArrow } from "react-icons/bs";

import {
  StarFilled,
  FileTextOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";


export default function StarLinemarket() {
  const [users, setUsers] = useState([]);
  const [sliders, setUserslider] = useState([]);
  const [Slider_url, setSlider_url] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loaduser();
  }, []);

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id)
    setLoading(true);

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/starline_market.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);
    // formData.append("type", 'starline');
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 1000);
      });
  };


  // const handleClick = (id) => {
  //   window.location.href = `/Pana?id=${id}`;
  // };
  const handleClick = (id, name) => {
    localStorage.setItem('market_id', id);
    localStorage.setItem('market_name', name);
    // localStorage.setItem('market_name',name);
    // window.location.href = `/Jodi-Game`;
    navigate('/Starline-Pana');
  };

  const handleClick1 = async (id) => {
    navigate('/Result-starline');
    localStorage.setItem('marketchartstarline_id',id,);
    // const market_id = localStorage.getItem("market_idd");

    // localStorage.setItem('market_idd', id);
    // try {
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}/webviewresultstarline.php?id=${id}`);
    //   const result = await response.text(); // Assuming the API returns HTML content
    //   const newWindow = window.open('', '_blank');
    //   newWindow.document.write(result);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }
  };

  const handleClickclose = (id, name) => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(500);
    }
  };
  return (
    <>
      <section id="play" className="margin-bottom-88 mb-0">
        <div className="playsection">

        <div className="height_65">

        <div className=" border-radius-8">
              <div className="playg">
                {users &&
                  users.map((user) => (
                    <>
                      <div className="new">
                        {user.is_play == 1 ? (
                          <div className="d-flex align-items-center justify-content-between padding-5">
                            <div className="div text-white text-center chartimage_close">
                            <div className="chart_name_heading">

                                <span onClick={() => {
                                  handleClick1(user.id);
                                }} className="d-block">
                                  <BsGraphUpArrow className="croseimg" />
                                </span>
                                <p className="text-bold">Chart</p>

                              </div>
                            </div>
                            <div className="div">
                              <div className="marketname text-center">
                                <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                                  {user.name}
                                </h4>
                                <div className="inputbox">
                                    <p style={{ color: "white" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2}
                                    </p>
                                  </div>

                                <p
                                  onClick={() => {
                                    handleReload();
                                  }}
                                  className="playgames p-0 "
                                >
                                  Market opened
                                </p>

                                <div className="marketnamepaly">
                                  <div className="date_time">
                                    <p className="animate-charcter">
                                      <span className="openclose"></span>{" "}
                                      {user.Opentime}
                                    </p>
                                    <p>||</p>
                                    <p className="animate-charcter">
                                      {user.Closetime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                            <div
                              onClick={() => {
                                handleClick(user.id, user.name);
                              }}
                              className="div text-dark text-center"
                            >
                              <div className="playbtn_open">
                                  <div>
                                  <IoMdPlay />
                              <p>Play</p>
                                  </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div className="d-flex    align-items-center justify-content-between padding-5">
                            <div className="div text-white text-center chartimage_open">
                              <div className="chart_name_heading">
                                <span className="d-block"   onClick={handleClickclose}>
                                  <BsGraphUpArrow className="croseimg" />
                                </span>
                                <p>Chart</p>
                              </div>
                            </div>
                            <div className="div">
                              <div className="marketname text-center">
                                <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center">
                                  {user.name}
                                </h4>
                                <div className="inputbox">
                                    <p style={{ color: "white" }}>
                                      {user.marketResult1 == null ? '***' : user.marketResult1} - {user.marketResult2 == null ? '**' : user.marketResult2}
                                    </p>
                                  </div>

                                <p
                                  onClick={() => {
                                    handleReload();
                                  }}
                                  className="playgames1 p-0 "
                                >
                                  Market Closed{" "}
                                </p>
                                <div className="marketnamepaly ">
                                  <div className="date_time ">
                                    <p className="animate-charcter">
                                       {user.Opentime}
                                    </p>
                                    <p>||</p>
                                    <p className="animate-charcter">
                                    {user.Closetime}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="div text-dark text-center"   onClick={handleClickclose}>
                              <div className="playbtn_close">
                                <div>
                                <IoMdPlay />
                              <p>Play</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </div>
</div>
        </div>
        {loading1 && (
          // <>
          // <div className="loader_roomcode">
          //   <img src={loader} alt="loader" />
          // </div>
          // </>
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )}
      </section>
    </>
  );
}
